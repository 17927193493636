import React, { useRef } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import { OrbitControls, useGLTF } from '@react-three/drei';
import * as THREE from 'three';

function RotatingHondaLogo() {
  const logoRef = useRef();

  useFrame(() => {
    if (logoRef.current) {
      // Continuous rotation without restrictions
      logoRef.current.rotation.y += 0.005; // Adjust the rotation speed
      logoRef.current.rotation.x += 0.0025; // Adds a slight tilt to the rotation
    }
  });

  const { scene } = useGLTF('/3Dlogos/LU.glb');

  // Center the model on the Y-axis if needed
  scene.position.set(0, 0, 0);

  return <primitive object={scene} ref={logoRef} scale={[55, 55, 55]} />;
}

export default function LULogoModel() {
  const controlsRef = useRef();

  return (
    <Canvas 
      style={{ width: '100%'}} // Adjust height as needed
      camera={{ position: [0, 0, 20], fov: 45, near: 0.1 }} // Adjust camera position and FOV
    >
      <ambientLight />
      <directionalLight intensity={1} />
      <RotatingHondaLogo />
      <OrbitControls
        ref={controlsRef}
        enableZoom={false} // Disable zooming
        enableRotate={true} // Enable rotation
        enablePan={false} // Disable panning (prevents right-click movement)
        enableDamping={true}
        dampingFactor={0.1}
        rotateSpeed={0.5}
        mouseButtons={{
          LEFT: THREE.MOUSE.ROTATE, // Allow rotation with left click
          RIGHT: THREE.MOUSE.ROTATE, // Restrict right-click to rotate (no pan)
          MIDDLE: THREE.MOUSE.DOLLY, // Disable middle mouse button zooming if desired
        }}
      />
    </Canvas>
  );
}