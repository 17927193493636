import React, { useRef, useEffect } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import { OrbitControls, useGLTF } from '@react-three/drei';
import * as THREE from 'three';

function RotatingHondaLogo() {
  const logoRef = useRef();

  useFrame(() => {
    if (logoRef.current) {
      // Continuous rotation without restrictions
      logoRef.current.rotation.y += 0.005; // Adjust the rotation speed
      logoRef.current.rotation.x += 0.0025; // Adds a slight tilt to the rotation
    }
  });

  const { scene } = useGLTF('/3Dlogos/Georgian.glb');

  useEffect(() => {
    if (logoRef.current) {
      // Calculate the bounding box of the loaded model
      const box = new THREE.Box3().setFromObject(logoRef.current);
      const size = new THREE.Vector3();
      box.getSize(size);

      // Calculate a suitable scale based on the size and desired distance from the camera
      const maxDim = Math.max(size.x, size.y, size.z);
      const desiredSize = 43; // Increase the desired size for a larger logo
      const scale = desiredSize / maxDim;

      // Apply the scale to the logo
      logoRef.current.scale.setScalar(scale);

      // Optionally center the model
      const center = new THREE.Vector3();
      box.getCenter(center);
      logoRef.current.position.sub(center); // Center the object in the scene
    }
  }, [scene]);

  return <primitive object={scene} ref={logoRef} />;
}

export default function GeorgianLogoModel() {
  const controlsRef = useRef();

  return (
    <Canvas 
      style={{ width: '100%', height: '200px' }} // Adjust height as needed
      camera={{ position: [0, 0, 50], fov: 60, near: 0.1 }} // Further adjusted camera position
    >
      <ambientLight />
      <directionalLight intensity={1} />
      <RotatingHondaLogo />
      <OrbitControls
        ref={controlsRef}
        enableZoom={false} // Disable zooming
        enableRotate={true} // Enable rotation
        enablePan={false} // Disable panning (prevents right-click movement)
        enableDamping={true}
        dampingFactor={0.1}
        rotateSpeed={0.5}
        mouseButtons={{
          LEFT: THREE.MOUSE.ROTATE, // Allow rotation with left click
          RIGHT: THREE.MOUSE.ROTATE, // Restrict right-click to rotate (no pan)
          MIDDLE: THREE.MOUSE.DOLLY, // Disable middle mouse button zooming if desired
        }}
      />
    </Canvas>
  );
}