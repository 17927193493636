import React, { useRef, Suspense, useEffect, useState } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import { OrbitControls, useGLTF } from '@react-three/drei';
import * as THREE from 'three';

function RotatingLogo({ scale }) {
  const logoRef = useRef();

  // Add some basic rotation or interaction
  useFrame(() => {
    if (logoRef.current) {
      logoRef.current.rotation.x += 0.005; // Slow down rotation on the x-axis
      logoRef.current.rotation.y += 0.005; // Slow down rotation on the y-axis
    }
  });

  // Load the GLTF model
  const { scene } = useGLTF('/3Dlogos/hormann3D.glb'); // Ensure this path is correct

  return <primitive object={scene} ref={logoRef} scale={scale} />; // Apply dynamic scale
}

export default function HormannLogoModel() {
  const [cameraPosition, setCameraPosition] = useState([0, 0, 15]);
  const [canvasHeight, setCanvasHeight] = useState('400px');
  const [logoScale, setLogoScale] = useState([10, 10, 10]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setCameraPosition([0, 0, 20]); // Adjust camera position for mobile
        setCanvasHeight('210px'); // Adjust canvas height for mobile
        setLogoScale([19, 19, 19]); // Increase scale for mobile
      } else {
        setCameraPosition([0, 0, 15]); // Camera position for desktop
        setCanvasHeight('400px'); // Canvas height for desktop
        setLogoScale([10, 10, 10]); // Default scale for desktop
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Call the function on component mount

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Canvas 
      style={{ width: '100%', height: canvasHeight }} // Dynamically set height based on screen size
      camera={{ position: cameraPosition, fov: 50 }} // Dynamically adjust camera position
    >
      <ambientLight intensity={0.8} />
      <directionalLight position={[5, 5, 5]} />
      <Suspense fallback={null}>
        <RotatingLogo scale={logoScale} /> {/* Pass dynamic scale as prop */}
      </Suspense>
      <OrbitControls 
        enableZoom={false} // Disable zooming
        enableRotate={true} // Enable rotation
        enablePan={false} // Disable panning (prevents right-click movement)
        enableDamping={true} 
        dampingFactor={0.1} 
        rotateSpeed={0.5}
        mouseButtons={{
          LEFT: THREE.MOUSE.ROTATE, // Allow rotation with left click
          RIGHT: THREE.MOUSE.ROTATE, // Restrict right-click to rotate (no pan)
          MIDDLE: THREE.MOUSE.DOLLY, // Disable middle mouse button zooming if desired
        }}
      />
    </Canvas>
  );
}
