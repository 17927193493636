import React, { useRef, useEffect, useState } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import { OrbitControls, useGLTF } from '@react-three/drei';
import * as THREE from 'three';

function RotatingHondaLogo({ scaleMultiplier }) {
  const logoRef = useRef();

  useFrame(() => {
    if (logoRef.current) {
      // Continuous rotation without restrictions
      logoRef.current.rotation.y += 0.005; // Adjust the rotation speed
      logoRef.current.rotation.x += 0.0025; // Adds a slight tilt to the rotation
    }
  });

  const { scene } = useGLTF('/3Dlogos/yr.glb');

  useEffect(() => {
    if (logoRef.current) {
      // Calculate the bounding box of the loaded model
      const box = new THREE.Box3().setFromObject(logoRef.current);
      const size = new THREE.Vector3();
      box.getSize(size);

      // Calculate a suitable scale based on the size and desired distance from the camera
      const maxDim = Math.max(size.x, size.y, size.z);
      const desiredSize = 50 * scaleMultiplier; // Increase or decrease the desired size
      const scale = desiredSize / maxDim;

      // Apply the scale to the logo
      logoRef.current.scale.setScalar(scale);

      // Optionally center the model
      const center = new THREE.Vector3();
      box.getCenter(center);
      logoRef.current.position.sub(center); // Center the object in the scene
    }
  }, [scene, scaleMultiplier]);

  return <primitive object={scene} ref={logoRef} />;
}

export default function YRLogoModel() {
  const controlsRef = useRef();
  const [isMobile, setIsMobile] = useState(false);

  // Detect if the user is on mobile
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust threshold as needed for mobile
    };

    handleResize(); // Call it once initially to set the correct state
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const cameraPosition = isMobile ? [0, 0, 100] : [0, 0, 80]; // Adjust the camera position for mobile
  const scaleMultiplier = isMobile ? 1 : 0.55; // Smaller logo on mobile, larger on desktop

  return (
    <Canvas
      style={{ width: '100%', height: isMobile ? '200px' : '400px' }} // Adjust height for mobile/desktop
      camera={{ position: cameraPosition, fov: 40 }} // Adjusted camera position based on screen size
    >
      <ambientLight />
      <directionalLight intensity={1} />
      <RotatingHondaLogo scaleMultiplier={scaleMultiplier} />
      <OrbitControls
        ref={controlsRef}
        enableZoom={false} // Disable zooming
        enableRotate={true} // Enable rotation
        enablePan={false} // Disable panning (prevents right-click movement)
        enableDamping={true}
        dampingFactor={0.1}
        rotateSpeed={0.9}
        mouseButtons={{
          LEFT: THREE.MOUSE.ROTATE, // Allow rotation with left click
          RIGHT: THREE.MOUSE.ROTATE, // Restrict right-click to rotate (no pan)
          MIDDLE: THREE.MOUSE.DOLLY, // Disable middle mouse button zooming if desired
        }}
      />
    </Canvas>
  );
}
