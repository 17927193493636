import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/home';  // Import your home.js component

function App() {
  return (
    <Router>
      <Routes>
        {/* Route for the home component */}
        <Route path="/" element={<Home />} />
        {/* Other routes can be added here */}
      </Routes>
    </Router>
  );
}

export default App;
